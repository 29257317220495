import {Component, AfterViewInit, Input} from '@angular/core';

@Component({
  selector: 'app-ad-sense',
  templateUrl: './ad-sense.component.html',
  styleUrls: ['./ad-sense.component.css']
})

export class AdSenseComponent implements AfterViewInit {
  @Input() slot = '';

  constructor() {
  }

  ngAfterViewInit() {
    try {
      (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
    } catch (e) {
      // console.error('error loading ad ' + e);
    }
  }
}