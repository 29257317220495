import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {

  selectedExperience$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() { }

  getSelectedExp() {
    return this.selectedExperience$;
  }

  setSelectedExp(e: number) {
    this.selectedExperience$.next(e);
  }
}
