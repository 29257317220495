import { Component, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TopGodsService } from '../../services/top-gods.service';
import { PatchService } from '../../services/patch.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GodsService } from '../../services/gods.service';
import { ExperienceService } from '../../services/experience.service';
import { SeoService } from '../../services/seo.service';
import { Subscription } from 'rxjs';

declare var $: any;

export interface ModeDetailsGodElement {
  I: string;
  N: string;
  S: number;
  '%': number;
  KDA: number;
  DA: number;
  H: number;
  M: number;
  R: string;
  P: string;
}

export interface ModeDetailsCompositionElement {
  N: string[];
  S: number;
  '%': number;
}

@Component({
  selector: 'app-mode-details',
  templateUrl: './mode-details.component.html',
  styleUrls: ['./mode-details.component.css']
})
export class ModeDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('godsTable', {read: MatSort}) godsSort: MatSort;
  @ViewChild('compositionTable', {read: MatSort}) compositionSort: MatSort;

  @ViewChild('godsPaginator') godsPaginator: MatPaginator;
  @ViewChild('compositionPaginator') compositionPaginator: MatPaginator;

  dataGods: MatTableDataSource<{}>;
  dataCompositions: MatTableDataSource<{}>;

  displayedColumnsGods = ['N', '%', 'S', 'KDA', 'DA', 'H', 'M'];
  displayedColumnsComposition = ['N', '%', 'S'];

  modeName: string;
  mostGods: {};
  mostCompositions = 0;
  activeRole = 'all roles';
  activePantheon = 'all pantheons';
  allGodsArr: ModeDetailsGodElement[] = [];
  allCompositionsArr: ModeDetailsCompositionElement[] = [];
  shownGodsArr: ModeDetailsGodElement[] = [];
  shownCompositionsArr: ModeDetailsCompositionElement[] = [];
  roles: string[] = ['assassin', 'guardian', 'hunter', 'mage', 'warrior'];
  compositionChoice: any[];

  loadingGods = true;
  loadingCompositions = true;

  subscriptions: Subscription[] = [];
  paramsSubscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private topGodsService: TopGodsService,
    private godsService: GodsService,
    private patchService: PatchService,
    private experienceService: ExperienceService,
    private changeDetectorRef: ChangeDetectorRef,
    private seoService: SeoService
    ) {}

  ngOnInit() {
    this.mostGods = {
      matches: 0,
      kda: 0,
      damage: 0,
      healing: 0,
      mitigated: 0
    };

    this.paramsSubscriptions.push(this.route.paramMap.subscribe((params: ParamMap) => {
      const queues = ['arena', 'assault', 'conquest', 'joust', 'slash'];
      this.modeName = params.get('name');
      if (!queues.includes(this.modeName)) {
        this.router.navigate(['']);
      }

      this.seoService.setSeo('modeDetails', this.modeName);

      if (this.modeName === 'joust') {
        this.compositionChoice = [['noSelection', 0], ['noSelection', 1], ['noSelection', 2]];
      } else if (this.modeName === 'siege') {
        this.compositionChoice = [['noSelection', 0], ['noSelection', 1], ['noSelection', 2], ['noSelection', 3]];
      } else {
        this.compositionChoice = [['noSelection', 0], ['noSelection', 1], ['noSelection', 2], ['noSelection', 3], ['noSelection', 4]];
      }

      this.subscriptions.push(this.patchService.getSelectedPatch().subscribe(sp => {
      if (sp == null) {
        return;
      }

      this.subscriptions.slice(1).forEach(subscription => subscription.unsubscribe());

      this.loadingGods = true;
      this.loadingCompositions = true;

      this.activeRole = 'all roles';
      this.activePantheon = 'all pantheons';

      this.subscriptions.push(this.experienceService.getSelectedExp().subscribe((exp: number) => {

        this.loadingGods = true;
        this.loadingCompositions = true;

        this.subscriptions.push(this.topGodsService.getGods(this.modeName, sp, exp).subscribe((topG: any) => {
          if (topG == null) {
            return;
          }

          this.mostGods = {
            matches: 0,
            kda: 0,
            damage: 0,
            healing: 0,
            mitigated: 0
          };

          this.subscriptions.push(this.godsService.getGods().subscribe(gods => {
            if (gods == null) {
              return;
            }

            this.shownGodsArr = [];
            for (const g of Object.keys(topG)) {

              if (gods[g]) {
                let kda = +((+topG[g]['A'] / 2 + +topG[g]['K']) / +topG[g]['D']).toFixed(2);
                if (isNaN(kda)) {
                  kda = 0;
                }
                let winP = +(100 * topG[g]['W'] / topG[g]['S']).toFixed(2);
                if (isNaN(winP)) {
                  winP = 0;
                }

                this.shownGodsArr.push({
                  I: topG[g]['I'],
                  N: g,
                  S: topG[g]['S'],
                  '%': winP,
                  KDA: kda,
                  DA: topG[g]['DA'],
                  H: topG[g]['H'],
                  M: topG[g]['M'],
                  R: gods[g]['R'],
                  P: gods[g]['P']
                });

                if (topG[g]['S'] > this.mostGods['matches']) {
                  this.mostGods['matches'] = topG[g]['S'];
                }
                if (kda > this.mostGods['kda']) {
                  this.mostGods['kda'] = kda;
                }
                if (topG[g]['DA'] > this.mostGods['damage']) {
                  this.mostGods['damage'] = topG[g]['DA'];
                }
                if (topG[g]['H'] > this.mostGods['healing']) {
                  this.mostGods['healing'] = topG[g]['H'];
                }
                if (topG[g]['M'] > this.mostGods['mitigated']) {
                  this.mostGods['mitigated'] = topG[g]['M'];
                }
              }
            }

            this.allGodsArr = this.shownGodsArr;

            setTimeout(() => {
              this.dataGods = new MatTableDataSource(this.shownGodsArr);
              this.dataGods.sort = this.godsSort;
              this.dataGods.paginator = this.godsPaginator;
              if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
              }
            }, 1);

            this.loadingGods = false;
          }));
        }));

        this.subscriptions.push(this.topGodsService.getCompositions(this.modeName, sp, exp).subscribe((comp: any) => {
          if (comp == null) {
            return;
          }

          this.mostCompositions = 0;

          this.shownCompositionsArr = [];
          for (const c of Object.keys(comp)) {

            const compositonRoles = [];
            for (let i = 0; i < c.length; i++) {
              if (c.charAt(i) === 'A') {
                compositonRoles.push('assassin');
              } else if (c.charAt(i) === 'G') {
                compositonRoles.push('guardian');
              } else if (c.charAt(i) === 'H') {
                compositonRoles.push('hunter');
              } else if (c.charAt(i) === 'M') {
                compositonRoles.push('mage');
              } else if (c.charAt(i) === 'W') {
                compositonRoles.push('warrior');
              }
            }

            this.shownCompositionsArr.push({
              N: compositonRoles,
              S: comp[c]['S'],
              '%': comp[c]['%']
            });

            if (comp[c]['S'] > this.mostCompositions) {
              this.mostCompositions = comp[c]['S'];
            }
          }

          this.allCompositionsArr = this.shownCompositionsArr;

          setTimeout(() => {
            this.dataCompositions = new MatTableDataSource(this.shownCompositionsArr);
            this.dataCompositions.sort = this.compositionSort;
            this.dataCompositions.paginator = this.compositionPaginator;
            if (!this.changeDetectorRef['destroyed']) {
              this.changeDetectorRef.detectChanges();
            }
          }, 1);

          this.loadingCompositions = false;
        }));
      }));
    }));
  }));
}

  ngOnDestroy() {
    this.paramsSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onPaginatorClickGods(event) {
    document.getElementById('scrollGods').scrollIntoView();
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  imageURLRefactor(str: string) {
    return str.replace('\'', '').replace(/\s+/g, '-').toLowerCase();
  }

  search(role: string, pantheon: string) {

    this.shownGodsArr = this.allGodsArr;

    if (pantheon === null) {
      $('#' + this.activeRole).removeClass('active');
      $('#' + role).addClass('active');
      this.activeRole = role;
    } else if (role === null) {
      $('#' + this.activePantheon).removeClass('active');
      $('#' + pantheon).addClass('active');
      this.activePantheon = pantheon;
    }

    if (this.activeRole !== 'all roles') {
      this.shownGodsArr = this.shownGodsArr.filter(res => {
        return res.R.toLocaleLowerCase().match(this.activeRole);
      });
    }

    if (this.activePantheon !== 'all pantheons') {
      this.shownGodsArr = this.shownGodsArr.filter(res => {
        return res.P.toLocaleLowerCase().match(this.activePantheon);
      });
    }

    this.dataGods = new MatTableDataSource(this.shownGodsArr);
    this.dataGods.sort = this.godsSort;
    this.dataGods.paginator = this.godsPaginator;
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  buildComposition(role: string, id: number) {
    this.compositionChoice[id][0] = role;
    const counts = {};
    this.compositionChoice.forEach(x => { counts[x[0]] = (counts[x[0]] || 0) + 1; });

    if ((this.modeName === 'joust' && counts['noSelection'] === 3) || (this.modeName === 'siege' && counts['noSelection'] === 4) || counts['noSelection'] === 5) {
      this.shownCompositionsArr = this.allCompositionsArr;
    } else {
      let i = 0;
      for (const c in counts) {
        if (c !== 'noSelection') {
          if (i === 0) {
            this.shownCompositionsArr = this.allCompositionsArr.filter(res => {
              return this.checkArrayContains(res.N, c, counts[c]);
            });
          } else {
            this.shownCompositionsArr = this.shownCompositionsArr.filter(res => {
              return this.checkArrayContains(res.N, c, counts[c]);
            });
          }
          i++;
        }
      }
    }

    this.dataCompositions = new MatTableDataSource(this.shownCompositionsArr);
    this.dataCompositions.sort = this.compositionSort;
    this.dataCompositions.paginator = this.compositionPaginator;
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  // check if array contains "num" of "str"
  checkArrayContains(arr: any[], str: string, num: number) {
    for (const x of arr) {
      if (x === str) {
        if (num > 1) {
          num--;
        } else {
          return true;
        }
      }
    }
    return false;
  }

}
