import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GodsService } from '../../services/gods.service';
import { God } from 'src/models/god.model';
import { SeoService } from '../../services/seo.service';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-gods',
  templateUrl: './gods.component.html',
  styleUrls: ['./gods.component.css']
})
export class GodsComponent implements OnInit {
  gods: God[];
  shownGods: God[];

  query: string;
  activeFilter: string;

  loading = true;

  constructor(
    private godsService: GodsService,
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService
    ) {}

  ngOnInit() {
    this.seoService.setSeo('gods');

    this.gods = [];
    this.shownGods = [];

    this.query = '';
    this.activeFilter = 'all';

    this.godsService.getGods().pipe(take(1)).subscribe((gods: God[]) => {
      if (gods == null) {
        return;
      }
      this.loading = false;
      for (const god of Object.keys(gods)) {
        this.gods.push({
          N: god,
          R: gods[god].R,
          I: gods[god].I
        });
      }
      this.shownGods = this.gods;
    });
  }

  onSelect(name: string) {
    this.router.navigate([name.toLocaleLowerCase()], {relativeTo: this.route});
  }

  search(value: string) {
    if (value === '') {
      value = this.activeFilter;
    } else {
      $('#' + this.activeFilter).removeClass('active');
      $('#' + value).addClass('active');
      this.activeFilter = value;
    }

    if (value !== 'all') {
      this.shownGods = this.gods.filter(res => res.R.toLocaleLowerCase().match(value));
    } else {
      this.shownGods = this.gods;
    }

    this.shownGods = this.shownGods.filter(res => res.N.toLocaleLowerCase().match(this.query.trim().toLowerCase()));
  }

  imageURLRefactor(str: string) {
    return str.replace('\'', '').replace(/\s+/g, '-').toLowerCase();
  }
}
