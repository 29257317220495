import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { GodsService } from './gods.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
    private godsService: GodsService
  ) { }

  public setSeo(page: string, name?: string) {
    let newTitle = '';
    let newDescription = '';
    let newKeywords = '';

    this.godsService.getGodsNames().pipe(take(1)).subscribe(gods => {
      if (page === 'home') {
        newTitle = 'Normal SMITE Stats - Casual Smite';
        newDescription = 'Latest stats for all normal modes in SMITE: Arena, Assault, Conquest, Joust and Slash. With hourly updates and graphs. Get recommended items to buy per mode. God stats include win rate, recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Sort Gods by win rate and see which Gods are current top performers.';
        newKeywords = 'smite,smite stats,normal stats,casual smite,smite items,smite gods,arena stats,assault stats,conquest stats,' +
                      'joust stats,slash stats,statistics,stats,arena,assault,conquest,joust,clash,slash,casual,' +
                      'win rate,god counters,pentakill';
      } else if (page === 'gods') {
        newTitle = 'Gods - Normal SMITE Stats - Casual Smite';
        newDescription = 'Latest stats for all normal modes in SMITE: Arena, Assault, Conquest, Joust and Slash. With hourly updates and graphs. Get recommended items to buy per mode. God stats include win rate, recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Sort Gods by win rate and see which Gods are current top performers.';
        newKeywords = 'smite,smite stats,normal stats,casual smite,smite items,smite gods,arena stats,assault stats,conquest stats,' +
                      'joust stats,slash stats,statistics,stats,arena,assault,conquest,joust,clash,slash,casual,' +
                      'win rate,god counters,pentakill,all gods,gods';
      } else if (page === 'godDetails') {
        newTitle = `${this.capitalize(name)} - Normal SMITE Stats - Casual Smite`;
        newDescription = `Latest stats for ${this.capitalize(name)} in SMITE - Arena, Assault, Conquest, Joust and Slash. With hourly updates and graphs. ` +
                         `Get recommended items to buy per mode. ${this.capitalize(name)} win rate, recommended items, counters, ` +
                         `popularity, average kills, assists, damage...`;
        newKeywords = `smite,smite ${this.capitalize(name)},smite stats,normal stats,casual smite,smite items,smite gods,arena stats,assault stats,conquest stats,` +
                      `joust stats,slash stats,statistics,stats, arena,assault,conquest,joust,slash,casual,` +
                      `win rate,god counters,pentakill,${this.capitalize(name)},${this.capitalize(name)} stats`;
      } else if (page === 'donate') {
        newTitle = 'Support - Normal SMITE Stats - Casual Smite';
        newDescription = 'Support Casual Smite and help us keep it alive. Latest stats for all normal modes in SMITE: Arena, Assault, Conquest, Joust and Slash. With hourly updates and graphs. Get recommended items to buy per mode. God stats include win rate, recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Sort Gods by win rate and see which Gods are current top performers.';
        newKeywords = 'smite,smite stats,normal stats,casual smite,smite items,smite gods,arena stats,assault stats,conquest stats,joust stats,' +
                      'slash stats,statistics,stats,arena,assault,conquest,joust,slash,casual,win rate,god counters,pentakill,support,donate';
      } else if (page === 'about') {
        newTitle = 'About - Normal SMITE Stats - Casual Smite';
        newDescription = 'Latest stats for all normal modes in SMITE: Arena, Assault, Conquest, Joust and Slash. With hourly updates and graphs. Get recommended items to buy per mode. God stats include win rate, recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Sort Gods by win rate and see which Gods are current top performers.';
        newKeywords = 'smite,smite stats,normal stats,casual smite,smite items,smite gods,arena stats,assault stats,conquest stats,joust stats,' +
                      'slash stats,statistics,stats,arena,assault,conquest,joust,slash,casual,win rate,god counters,pentakill';
      } else if (page === 'live') {
        newTitle = 'Live Stats - Normal SMITE Stats - Casual Smite';
        newDescription = 'Live stats for all normal modes in SMITE: Arena, Assault, Conquest, Joust and Slash. Enter your in-game name ' +
                         'and get your win chance. A win chance calculator for live matches.';
        newKeywords = 'smite,smite stats,normal stats,casual smite,smite items,smite gods,arena stats,assault stats,conquest stats,joust stats,' +
                      'slash stats,statistics,stats,arena,assault,conquest,joust,slash,casual,win rate,god counters,live stats,live,calculator';
      } else if (page === 'modeDetails') {
        newTitle = `${this.capitalize(name)} - Normal SMITE Stats - Casual Smite`;
        newDescription = `Latest stats for ${this.capitalize(name)} in SMITE. Includes win rate, popularity, average kills, ` +
                         `assists, damage, healing... Stats for different god compositions. Sort Gods by win rate and see which Gods are current top performers.`;
        newKeywords = `smite,smite stats,normal stats,casual smite,smite items,smite gods,${name} stats,statistics,stats,${name},casual` +
                      `,win rate,god counters,pentakill,compositions,smite god compositions,mage,warrior,hunter,assassin,guardian`;
      }

      newKeywords += ',' + gods;

      this.title.setTitle(newTitle);
      this.meta.updateTag({name: 'description', content: newDescription});
      this.meta.updateTag({name: 'keywords', content: newKeywords});
      this.meta.updateTag({property: 'og:title', content: newTitle});
      this.meta.updateTag({property: 'og:description', content: newDescription});
      this.meta.updateTag({property: 'twitter:title', content: newTitle});
      this.meta.updateTag({property: 'twitter:description', content: newDescription});
    });
  }

  capitalize(str: string) {
    return str.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  }
}
