import { Component, OnInit } from '@angular/core';
import { ExperienceService } from 'src/app/services/experience.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { PatchService } from 'src/app/services/patch.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  public expPlayers = true;
  public newPlayers = false;
  public selectedPatch: string;
  public selectedExp = 0;
  public patches: string[];

  constructor(
    public experienceService: ExperienceService,
    public sidenavService: SidenavService,
    public patchService: PatchService
  ) { }

  ngOnInit() {
    this.patchService.getPatches().subscribe((patches: string[]) => {
      if (patches == null) {
        return;
      }
      this.patches = Object.values(patches).filter(p => p != '').map(p => p.replace('*', '.'));
      this.selectedPatch = this.patches[this.patches.length - 1];
    });
  }

  changeExperience() {
    this.experienceService.setSelectedExp(this.selectedExp);
  }

  changePatch() {
    this.patchService.setSelectedPatch(this.selectedPatch.replace(/\./g, '*'));
  }

  openRanked() {
    window.open('https://ranked.casualsmite.com', '_self');
  }

  donate() {
    window.open('https://www.patreon.com/casualsmite', '_blank');
  }

  openSocial() {
    window.open('https://twitter.com/casualsmitePR', '_blank');
  }
}
