import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { GodDetailsComponent } from './content/god-details/god-details.component';
import { HomeComponent } from './content/home/home.component';
import { NavComponent } from './content/nav/nav.component';
import { FooterComponent } from './content/footer/footer.component';
import { GodsComponent } from './content/gods/gods.component';
import { GodsService } from './services/gods.service';
import { ModeDetailsComponent } from './content/mode-details/mode-details.component';
import { LoadingSpinnerComponent } from './content/loading-spinner/loading-spinner.component';
import { GodDetailsService } from './services/god-details.service';
import { NumberMatchesService } from './services/number-matches.service';
import { PatchService } from './services/patch.service';
import { TopGodsService } from './services/top-gods.service';
import { AboutComponent } from './content/about/about.component';
import { ExperienceService } from './services/experience.service';
import { LiveStatsComponent } from './content/live-stats/live-stats.component';
import { LiveService } from './services/live.service';
import { MaintenanceComponent } from './content/maintenance/maintenance.component';
import { Page404Component } from './content/page404/page404.component';
import { AppRoutingModule } from './app-routing.module';
import { AdSenseComponent } from './content/ad-sense/ad-sense.component';
import { SidenavComponent } from './content/sidenav/sidenav.component';
import { SeoService } from './services/seo.service';
import { SidenavService } from './services/sidenav.service';

@NgModule({
  declarations: [
    AppComponent,
    GodDetailsComponent,
    HomeComponent,
    NavComponent,
    FooterComponent,
    GodsComponent,
    ModeDetailsComponent,
    LoadingSpinnerComponent,
    AboutComponent,
    LiveStatsComponent,
    MaintenanceComponent,
    Page404Component,
    AdSenseComponent,
    SidenavComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'CasualSmite'),
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatSnackBarModule,
    FontAwesomeModule
  ],
  providers: [
    GodsService,
    GodDetailsService,
    NumberMatchesService,
    PatchService,
    TopGodsService,
    ExperienceService,
    LiveService,
    SidenavService,
    SeoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
