import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class NumberMatchesService {

  constructor(private db: AngularFireDatabase) { }

  getNumMatches(patch: string) {
    return this.db.object(patch.replace(/\./g, '*') + '/MA').valueChanges();
  }

  getNumMatchesMode(patch: string, mode: string) {
    return this.db.object(patch.replace(/\./g, '*') + '/MA' + mode).valueChanges();
  }

  getNumMatchesExperience(patch: string) {
    return this.db.object(patch.replace(/\./g, '*') + '/M').valueChanges();
  }

  getNumMatchesModeExperience(patch: string, mode: string) {
    return this.db.object(patch.replace(/\./g, '*') + '/M' + mode).valueChanges();
  }
}
