import { Component, OnInit } from '@angular/core';
import { PatchService } from '../../services/patch.service';
import { LiveService } from '../../services/live.service';
import { GodDetailsService } from '../../services/god-details.service';
import { SeoService } from '../../services/seo.service';
import { take } from 'rxjs/operators';
import { NgRecaptcha3Service } from 'ng-recaptcha3'

@Component({
  selector: 'app-live-stats',
  templateUrl: './live-stats.component.html',
  styleUrls: ['./live-stats.component.css']
})
export class LiveStatsComponent implements OnInit {

  smiteModes = {
    435: 'Arena',
    426: 'Conquest',
    448: 'Joust',
    445: 'Assault',
    10189: 'Slash',
    440: 'Duel',
    451: 'Conquest Ranked',
    450: 'Joust Ranked'
  };
  loading = false;
  name: string;
  resultReady = false;

  queue: string;
  playerStatus = 'start';
  t1WinChance = 0;
  t2WinChance = 0;
  team1GoodWith = 0;
  team2GoodWith = 0;
  team1GoodAgainst = 0;
  team2GoodAgainst = 0;
  query: string;
  gods: any = {
    team1: [],
    team2: [],
    t1Win: 0,
    t2Win: 0,
    numAll: 50
  };
  bestCombination: any = {
    n1: '',
    n2: '',
    '%': 0
  };
  bestCounter: any = {
    n1: '',
    n2: '',
    '%': 0
  };
  wr: any = {};

  constructor(
    private patchService: PatchService,
    private liveService: LiveService,
    private godDetailsService: GodDetailsService,
    private seoService: SeoService,
    private recaptcha3: NgRecaptcha3Service
  ) { }

  ngOnInit() {
    this.seoService.setSeo('live');
    this.recaptcha3.init('6LdNchghAAAAAB8J5_m6IZgvgfkdzsTMqDFGClWG');
  }

  calcWinChance(name: string) {
    this.loading = true;

    this.gods = {
      team1: [],
      team2: [],
      t1Win: 0,
      t2Win: 0,
      numAll: 50
    };

    this.name = name;
    this.patchService.getLatestPatch().subscribe((sp: string) => {
      if (sp == null) {
        return;
      }

      this.recaptcha3.getToken().then(token => {
        this.liveService.getLiveData(this.name, token).then(async (d: any) => {

          if (!d.playerStatus) {
            this.playerStatus = `Can\'t find player with a name ${this.name}. Make sure the account is not set to private.`;
          } else if (d.playerStatus === 'overload') {
            this.playerStatus = `The servers are overloaded. Please try again later.`;
          } else {
            const queueId = d.playerStatus.match_queue_id;
            this.queue = this.smiteModes[queueId];

            if (d.playerStatus.status === 3) {
              if (queueId === 435 ||
                  queueId === 426 ||
                  queueId === 448 ||
                  queueId === 445 ||
                  queueId === 459 ||
                  queueId === 466) {
                this.playerStatus = '';
              } else {
                this.playerStatus = `There is no data for ${this.queue}. For ranked matches check ranked.casualsmite.com`;
              }
            } else {
              this.playerStatus = `This player is not in a match. Status: ${d.playerStatus.status_string}`;
            }
          }

          this.resultReady = true;

          // team 1
          for (const playerTeam1 of d.team1) {
            let exp = 0;
            const GW = [];
            const GA = [];
            this.wr[playerTeam1.playerName] = {
              baseWr: 50,
              combined: 0,
              numAll: 0
            };

            if (playerTeam1.playerLevel <= 30) {
              exp = 1;
            }

            this.godDetailsService.getSampleSize(playerTeam1.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).pipe(take(1)).subscribe(async (ss: number) => {
              this.godDetailsService.getWin(playerTeam1.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).pipe(take(1)).subscribe((w: number) => {
                if (ss > 0) {
                  this.wr[playerTeam1.playerName].baseWr = ((w / ss) * 100).toFixed(2);
                  this.wr[playerTeam1.playerName].combined += (w / ss) * 100;
                  this.gods.t1Win += (w / ss) * 100;
                } else {
                  this.wr[playerTeam1.playerName].baseWr = 50;
                  this.wr[playerTeam1.playerName].combined += 50;
                  this.gods.t1Win += 50;
                }
                this.wr[playerTeam1.playerName].numAll++;
              });
            });

            // good with on team 1
            for (const player of d.team1) {
              if (playerTeam1.name !== player.name) {
                await this.liveService.getCompareGoodWith(playerTeam1.name.toLowerCase(), player.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).subscribe((p: string) => {
                  GW.push({
                    '%': parseFloat(p),
                    n1: playerTeam1.name,
                    n2: player.name
                  });
                  this.wr[playerTeam1.playerName].combined += parseFloat(p);
                  this.gods.t1Win += parseFloat(p);
                  this.wr[playerTeam1.playerName].numAll++;
                  if (parseFloat(p) > this.bestCombination['%']) {
                    this.bestCombination.n1 = playerTeam1.name;
                    this.bestCombination.n2 = player.name;
                    this.bestCombination['%'] = parseFloat(p);
                  }
                });
              }
            }

            // good against team 1 vs team 2
            for (const player of d.team2) {
              if (playerTeam1.name !== player.name) {
                await this.liveService.getCompareGoodAgainst(playerTeam1.name.toLowerCase(), player.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).pipe(take(1)).subscribe((p: string) => {
                  GA.push({
                    '%': parseFloat(p),
                    n1: playerTeam1.name,
                    n2: player.name
                  });
                  this.wr[playerTeam1.playerName].combined += parseFloat(p);
                  this.gods.t1Win += parseFloat(p);
                  this.wr[playerTeam1.playerName].numAll++;
                  if (parseFloat(p) > this.bestCounter['%']) {
                    this.bestCounter.n1 = playerTeam1.name;
                    this.bestCounter.n2 = player.name;
                    this.bestCounter['%'] = parseFloat(p);
                  }
                });
              } else {
                this.wr[playerTeam1.playerName].combined += 50;
                this.gods.t1Win += 50;
                this.wr[playerTeam1.playerName].numAll++;
              }
            }

            this.gods.team1.push({
              godName: playerTeam1.name,
              playerName: playerTeam1.playerName,
              playerLevel: playerTeam1.playerLevel,
              GW,
              GA
            });
          }


          // team 2
          for (const playerTeam2 of d.team2) {
            let exp = 0;
            const GW = [];
            const GA = [];
            this.wr[playerTeam2.playerName] = {
              baseWr: 50,
              combined: 0,
              numAll: 0
            };

            if (playerTeam2.playerLevel <= 30) {
              exp = 1;
            }

            this.godDetailsService.getSampleSize(playerTeam2.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).pipe(take(1)).subscribe(async (ss: number) => {
              this.godDetailsService.getWin(playerTeam2.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).pipe(take(1)).subscribe((w: number) => {
                if (ss > 0) {
                  this.wr[playerTeam2.playerName].baseWr = ((w / ss) * 100).toFixed(2);
                  this.wr[playerTeam2.playerName].combined += (w / ss) * 100;
                  this.gods.t2Win += (w / ss) * 100;
                } else {
                  this.wr[playerTeam2.playerName].baseWr = 50;
                  this.wr[playerTeam2.playerName].combined += 50;
                  this.gods.t2Win += 50;
                }
                this.wr[playerTeam2.playerName].numAll++;
              });
            });

            // good with on team 2
            for (const player of d.team2) {
              if (playerTeam2.name !== player.name) {
                await this.liveService.getCompareGoodWith(playerTeam2.name.toLowerCase(), player.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).pipe(take(1)).subscribe((p: string) => {
                  GW.push({
                    '%': parseFloat(p),
                    n1: playerTeam2.name,
                    n2: player.name
                  });
                  this.wr[playerTeam2.playerName].combined += parseFloat(p);
                  this.gods.t2Win += parseFloat(p);
                  this.wr[playerTeam2.playerName].numAll++;
                  if (parseFloat(p) > this.bestCombination['%']) {
                    this.bestCombination.n1 = playerTeam2.name;
                    this.bestCombination.n2 = player.name;
                    this.bestCombination['%'] = parseFloat(p);
                  }
                });
              }
            }

            // good against team 2 vs team 1
            for (const player of d.team1) {
              if (playerTeam2.name !== player.name) {
                await this.liveService.getCompareGoodAgainst(playerTeam2.name.toLowerCase(), player.name.toLowerCase(), sp, this.queue.toLowerCase(), exp).pipe(take(1)).subscribe((p: string) => {
                  GA.push({
                    '%': parseFloat(p),
                    n1: playerTeam2.name,
                    n2: player.name
                  });
                  this.wr[playerTeam2.playerName].combined += parseFloat(p);
                  this.gods.t2Win += parseFloat(p);
                  this.wr[playerTeam2.playerName].numAll++;
                  if (parseFloat(p) > this.bestCounter['%']) {
                    this.bestCounter.n1 = playerTeam2.name;
                    this.bestCounter.n2 = player.name;
                    this.bestCounter['%'] = parseFloat(p);
                  }
                });
              } else {
                this.wr[playerTeam2.playerName].combined += 50;
                this.gods.t2Win += 50;
                this.wr[playerTeam2.playerName].numAll++;
              }
            }

            this.gods.team2.push({
              godName: playerTeam2.name,
              playerName: playerTeam2.playerName,
              playerLevel: playerTeam2.playerLevel,
              GW,
              GA
            });
          }
          this.loading = false;
        });
      });
    });
  }

  imageURLRefactor(str: string) {
    return str.replace('\'', '').replace(/\s+/g, '-').toLowerCase();
  }

  getColor(pct: number) {
    return pct < 50 ? '#a33434' : '#4bb44b';
  }
}
