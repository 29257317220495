import { Component, OnInit } from '@angular/core';
import { PatchService } from '../../services/patch.service';
import { ExperienceService } from '../../services/experience.service';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  patches: string[] = [];
  selectedPatch: string;
  selectedExp = 0;

  constructor(
    private patchService: PatchService,
    private experienceService: ExperienceService,
    public sidenavService: SidenavService
    ) { }

  ngOnInit() {
    this.patches = [];

    this.patchService.getPatches().subscribe((patches: string[]) => {
      if (patches == null) {
        return;
      }
      this.patches = Object.values(patches).filter(p => p != '').map(p => p.replace('*', '.'));
      this.selectedPatch = this.patches[this.patches.length - 1];
    });
  }

  changeExperience() {
    this.experienceService.setSelectedExp(this.selectedExp);
  }

  changePatch() {
    this.patchService.setSelectedPatch(this.selectedPatch.replace(/\./g, '*'));
  }
}
