import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GodsService } from '../../services/gods.service';
import { TopGodsService } from '../../services/top-gods.service';
import { PatchService } from '../../services/patch.service';
import { NumberMatchesService } from '../../services/number-matches.service';
import { MatTableDataSource } from '@angular/material/table';
import { ExperienceService } from '../../services/experience.service';
import { SeoService } from '../../services/seo.service';
import { God } from 'src/models/god.model';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  @Output() myEvent = new EventEmitter();

  arenaDataSource: MatTableDataSource<{}>;
  assaultDataSource: MatTableDataSource<{}>;
  conquestDataSource: MatTableDataSource<{}>;
  joustDataSource: MatTableDataSource<{}>;
  slashDataSource: MatTableDataSource<{}>;

  displayedColumns = ['N', '%', 'S', 'KDA', 'DA'];
  queues = ['arena', 'assault', 'conquest', 'joust', 'slash'];

  selectedPatch: string;
  mostMatches = {
    arena: 0,
    assault: 0,
    conquest: 0,
    joust: 0,
    slash: 0
  };
  mostKills = {
    arena: 0,
    assault: 0,
    conquest: 0,
    joust: 0,
    slash: 0
  };
  mostDamage = {
    arena: 0,
    assault: 0,
    conquest: 0,
    joust: 0,
    slash: 0
  };
  matchesProcessed = 0;
  matchesProcessedNew = 0;
  matchesProcessedExp = 0;
  matchesProcessedMode = {
    arena: 0,
    assault: 0,
    conquest: 0,
    joust: 0,
    slash: 0
  };
  playersProcessedMode = {
    arena: 0,
    assault: 0,
    conquest: 0,
    joust: 0,
    slash: 0
  };

  godsSlideshow: string[][];

  loading = true;
  subscriptions: Subscription[] = [];

  constructor(
    private godsService: GodsService,
    private topGodsService: TopGodsService,
    private patchService: PatchService,
    private numberMatchesService: NumberMatchesService,
    private experienceService: ExperienceService,
    private changeDetectorRef: ChangeDetectorRef,
    private seoService: SeoService
  ) {}

  ngOnInit() {
    this.seoService.setSeo('home');

    this.subscriptions.push(this.patchService.getSelectedPatch().subscribe(sp => {
      if (!sp) {
        return;
      }

      this.subscriptions.slice(1).forEach(subscription => subscription.unsubscribe());

      this.selectedPatch = this.replaceStars(sp);

      this.mostMatches = {
        arena: 0,
        assault: 0,
        conquest: 0,
        joust: 0,
        slash: 0
      };
      this.mostKills = {
        arena: 0,
        assault: 0,
        conquest: 0,
        joust: 0,
        slash: 0
      };
      this.mostDamage = {
        arena: 0,
        assault: 0,
        conquest: 0,
        joust: 0,
        slash: 0
      };

      this.subscriptions.push(this.experienceService.getSelectedExp().subscribe((exp: number) => {

        this.matchesProcessed = 0;
        this.matchesProcessedNew = 0;
        this.matchesProcessedExp = 0;

        for (const q of this.queues) {
          const patchSplit = sp.split('*');
          if ((Number(patchSplit[0]) >= 7 && Number(patchSplit[1]) > 2) || Number(patchSplit[0]) > 7) {
            // New method of calculating number of matches since patch 7.2
            this.subscriptions.push(this.numberMatchesService.getNumMatchesModeExperience(sp, q).subscribe((n: string) => {
              if (!n) {
                return;
              }

              const numMatchesPerExp = n.split('~');
              if (exp === 0) {
                this.matchesProcessedMode[q] = numMatchesPerExp[0];
              } else {
                this.matchesProcessedMode[q] = numMatchesPerExp[1];
              }

              this.matchesProcessed += Number(numMatchesPerExp[0]) + Number(numMatchesPerExp[1]);
              this.matchesProcessedNew += Number(numMatchesPerExp[1]);
              this.matchesProcessedExp += Number(numMatchesPerExp[0]);
            }));
          } else {
            // Old method of calculating number of matches since patch 7.2
            this.subscriptions.push(this.numberMatchesService.getNumMatchesMode(sp, q).subscribe((p: number) => {
              if (p == null) {
                return;
              }
              this.matchesProcessedMode[q] = p;
              this.matchesProcessed += p;
            }));
          }
        }

        this.mostMatches = {
          arena: 0,
          assault: 0,
          conquest: 0,
          joust: 0,
          slash: 0
        };
        this.mostKills = {
          arena: 0,
          assault: 0,
          conquest: 0,
          joust: 0,
          slash: 0
        };
        this.mostDamage = {
          arena: 0,
          assault: 0,
          conquest: 0,
          joust: 0,
          slash: 0
        };

        this.subscriptions.push(this.godsService.getGodsSlideshow(sp, exp).subscribe((g: any) => {
          if (g == null) {
            return;
          }
          this.loading = true;
          this.godsSlideshow = [];
          for (const god of Object.keys(g)) {
            const newGod = new Array();
            newGod[0] = g[god].N.toUpperCase();
            newGod[1] = god;
            newGod[2] = g[god].C;
            this.godsSlideshow.push(newGod);
          }

          this.godsSlideshow = this.shuffle(this.godsSlideshow);

          setTimeout(() => {
            this.loading = false;
            setTimeout(() => {
              $('.slideshow').slick({
                lazyLoad: 'ondemand',
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3500,
                speed: 750,
                swipeToSlide: true,
                infinite: true,
                responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 4
                    }
                  },
                  {
                    breakpoint: 950,
                    settings: {
                      slidesToShow: 3
                    }
                  },
                  {
                    breakpoint: 750,
                    settings: {
                      slidesToShow: 2
                    }
                  },
                  {
                    breakpoint: 450,
                    settings: {
                      slidesToShow: 1
                    }
                  }
                ]
              });
              $('#slide').css('display', 'block');
            }, 1);
          }, 1);

          for (const q of this.queues) {
            this.subscriptions.push(this.topGodsService.getGods(q, sp, exp).subscribe((topG: any) => {
              if (topG == null) {
                return;
              }
              let arr: God[] = [];
              for (const g of Object.keys(topG)) {
                let kda = +((+topG[g].A / 2 + +topG[g].K) / +topG[g].D).toFixed(2);
                if (isNaN(kda)) {
                  kda = 0;
                }
                let p = +(100 * topG[g].W / topG[g].S).toFixed(2);
                if (isNaN(p)) {
                  p = 0;
                }
                arr.push({
                  I: topG[g].I,
                  N: g,
                  S: topG[g].S,
                  '%': p,
                  KDA: kda,
                  DA: topG[g].DA,
                });

                if (topG[g].S > this.mostMatches[q]) {
                  this.mostMatches[q] = topG[g].S;
                }
                if (kda > this.mostKills[q]) {
                  this.mostKills[q] = kda;
                }
                if (topG[g].DA > this.mostDamage[q]) {
                  this.mostDamage[q] = topG[g].DA;
                }
              }

              arr = arr.sort((a, b) => b['%'] - a['%']);

              if (q === 'arena') {
                this.arenaDataSource = new MatTableDataSource(arr.splice(0, 5));
              } else if (q === 'assault') {
                this.assaultDataSource = new MatTableDataSource(arr.splice(0, 5));
              } else if (q === 'conquest') {
                this.conquestDataSource = new MatTableDataSource(arr.splice(0, 5));
              } else if (q === 'joust') {
                this.joustDataSource = new MatTableDataSource(arr.splice(0, 5));
              } else if (q === 'slash') {
                this.slashDataSource = new MatTableDataSource(arr.splice(0, 5));
              }
              this.changeDetectorRef.detectChanges();
            }));
          }
        }));
      }));
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  imageURLRefactor(str: string) {
    return str.replace('\'', '').replace(/\s+/g, '-').toLowerCase();
  }

  replaceStars(s: string) {
    return s.replace(/\*/g, '.');
  }

  commarize(val: number) {
    const min = 0 || 1e3;
    // Alter numbers larger than 1k
    if (val >= min) {
      const units = ['k', 'M', 'B', 'T'];

      const order = Math.floor(Math.log(val) / Math.log(1000));

      const unitname = units[(order - 1)];
      const num = Math.floor(val / 1000 ** order);

      // output number remainder + unitname
      return num + unitname;
    }

    // return formatted original number
    return this.toLocaleString();
  }

  FormatLongNumber(value: number) {
    if (value === 0) {
      return 0;
    } else if (value < 100000) {
      // hundreds
      return value;
    } else if (value >= 100000 && value < 1000000) {
      // thousands
      return (value / 1000).toFixed(0) + 'k';
    } else if (value >= 1000000 && value < 1000000000) {
      // millions
      return (value / 1000000).toFixed(2) + 'M';
    } else if (value >= 1000000000 && value < 1000000000000) {
      // billions
      return (value / 1000000000).toFixed(2) + 'B';
    }
    return value;
  }

}
